<template>
  <v-dialog v-bind="dialogProps" @click:outside="$emit('close')">
    <v-card>
      <v-card-title primary-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="closeProps" @click="$emit('close')">
          <v-icon>{{ closeIcon }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="submitProps" @click="$emit('submit')">
          {{ submitLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseFormModal",
  props: {
    dialogProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del componente dialog",
    },
    title: {
      type: String,
      default: "",
      description: "Titulo del formulario",
    },
    closeProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del boton cerrar",
    },
    closeIcon: {
      type: String,
      default: "mdi-close",
      description: "Icono del boton cerrar",
    },
    submitProps: {
      type: Object,
      default: () => {},
      description: "Propiedades del boton submit",
    },
    submitLabel: {
      type: String,
      default: "Submit",
      description: "Nombre para el boton submit",
    },
  },
};
</script>
